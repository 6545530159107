import { FC, ImgHTMLAttributes } from 'react';
import { usePreload } from '~/common/hooks/usePreload';

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  srcVariations?: string[];
}

export const Image: FC<ImageProps> = ({ src, srcVariations, alt, ...rest }) => {
  usePreload(srcVariations ?? [src]);
  return <img src={src} alt={alt} {...rest} />;
};
