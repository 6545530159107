import { useEffect } from 'react';

export const usePreload = (urls: string[], as?: string) => {
  useEffect(() => {
    // Function to dynamically create and append link elements to preload images
    const preloadImages = () => {
      urls.forEach((url) => {
        if (document.querySelector(`link[href="${url}"]`)) return;
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = as ?? 'image';
        link.href = url;
        document.head.appendChild(link);
      });
    };

    // Preload images when the component mounts
    preloadImages();

    // Clean up when the component unmounts
    return () => {
      urls.forEach((url) => {
        const link = document.querySelector(`link[href="${url}"]`);
        if (link) {
          link.remove();
        }
      });
    };
  }, [as, urls]);

  return null;
};
